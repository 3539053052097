import React from "react"
import Layout from "../components/Layout" 
import { graphql } from "gatsby"

import t1 from "../images/book/2022_tinadonzelle/1.jpg"
import t2 from "../images/book/2022_tinadonzelle/2.jpg"
import t3 from "../images/book/2022_tinadonzelle/3.jpg"
import t4 from "../images/book/2022_tinadonzelle_2/1.jpg"
import t5 from "../images/book/2022_tinadonzelle_2/2.jpg"
import t6 from "../images/book/2022_tinadonzelle_2/3.jpg"

import v1 from "../images/book/2022_vibes/1.jpg"
import v2 from "../images/book/2022_vibes/2.jpg"
import v3 from "../images/book/2022_vibes/3.jpg"
import v4 from "../images/book/2022_vibes_2/1.jpg"
import v5 from "../images/book/2022_vibes_2/2.jpg"
import v6 from "../images/book/2022_vibes_2/3.jpg"

import j1 from "../images/book/2020_jpandrieu/1.jpg"
import j2 from "../images/book/2020_jpandrieu/2.jpg"
import j3 from "../images/book/2020_jpandrieu/3.jpg"
import j4 from "../images/book/2020_jpandrieu_2/1.jpg"
import j5 from "../images/book/2020_jpandrieu_2/2.jpg"
import j6 from "../images/book/2020_jpandrieu_2/3.jpg"

import c1 from "../images/book/2021_charlottelehir/1.jpg"
import c2 from "../images/book/2021_charlottelehir/2.jpg"
import c3 from "../images/book/2021_charlottelehir/3.jpg"
import c4 from "../images/book/2021_charlottelehir_2/1.jpg"
import c5 from "../images/book/2021_charlottelehir_2/2.jpg"
import c6 from "../images/book/2021_charlottelehir_2/3.jpg"

const WorkPage = ({ data }) => {

  return (
    <>
      <Layout>

        <div className="page-wrapper book">
          <br/>
          <h1>BOOK</h1>
          
          <div className="details">
            <div className="breadcrumb">/2022/BOOK/VIBES</div>
          </div>
    
          <img src={v4} />
          <img src={v5} />
          <img src={v6} />
          <img src={v1} />
          <img src={v2} />
          <img src={v3} />
          <p>Photographe Vibes</p>
          <br/>
          
          <div className="details">
            <div className="breadcrumb">/2022/BOOK/TINA_DONZELLE</div>
          </div>
    
          <img src={t1} />
          <img src={t2} />
          <img src={t3} />
          <img src={t4} />
          <img src={t5} />
          <img src={t6} />
          <p>Photographe Tina DONZELLE</p>
          <br/>
          
          <div className="details">
            <div className="breadcrumb">/2021/BOOK/CHARLOTTE_LE_HIR</div>
          </div>
    
          <img src={c1} />
          <img src={c2} />
          <img src={c3} />
          <img src={c4} />
          <img src={c5} />
          <img src={c6} />
          <p>Photographe Charlotte LE HIR</p>
          <br/>
          
          <div className="details">
            <div className="breadcrumb">/2020/BOOK/JEAN_PIERRE_ANDRIEU</div>
          </div>
    
          <img src={j1} />
          <img src={j2} />
          <img src={j3} />
          <img src={j4} />
          <img src={j5} />
          <img src={j6} />
          <p>Photographe Jean Pierre Andrieu</p>
          <br/>


        </div>
      </Layout>
    </>
  )
}

export default WorkPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
