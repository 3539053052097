import PropTypes from "prop-types"
import React from "react"
import Video from "./Video"
import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa"
import video_mp4 from "../images/middle.mp4"
import video_webm from "../images/middle.webm"

const Layout = ({ background, children }) => {

  return (
    <div className={background == "true" ? "frame video" : "frame"} >
      
      {(background == "true") && (
        <div className="video-wrapper">
          <div className="position-wrapper">
            <video autoPlay muted loop playsinline className="video-background">
              <source src={video_mp4} type="video/mp4" />
              <source src={video_webm} type="video/mp4" />
            </video>
          </div>
        </div>
      )}

        <div className="header-wrapper">

          <div className="header">

            <a href="/" className="logo-wrapper">
              <img src="/img/logo_black.png" className="logo"/>
              <h1>ALOÏS LECLET</h1>
            </a>

            <div className="nav">
              <a href="/work">projets</a>
              <a href="/cv">à propos</a>
              <a href="/collaborations">collaborations</a>
              <a href="/book">book</a>
            </div>

          </div>

        </div>
        

        <div className="main-wrapper video">
          { children } 
        </div>

        <div className="social">
          <a href="https://facebook.com/aloisleclet">
            <FaFacebook />
          </a>
          
          <a href="https://instagram.com/aloisleclet">
            <FaInstagram />
          </a>
        </div>

    </div> 
  )

}

Layout.propTypes = {
  background: PropTypes.string
}


export default Layout
